import React from 'react'

export interface GlobalConfig {
  idp: {
    authority: string
  }
  apiUrl?: string
  apiChangePassword?: string
  openReplayUrl?: string
  openReplayKey?: string
  isInline?: boolean
  enableAccountPanel?: boolean
  enableDealsOperation?: boolean
  fullErrorReport?: boolean
  disableFxNDF?: boolean
  qouteTimerMs?: number
  isAllowedSpot?: boolean
  minTermPeriodXCCY?: string
  maxTermPeriodXCCY?: string
  reportTheme?: string
  defaultDashboard?: string
  marqueeCcyPairs?: string[]
  ws?: string
  /**
   * Локальный репортер для работы в локальной сети банка
   */
  localReport?: string
  rnMessageNotifications?: boolean
  appLogo?: string
  /**
   * Ссылка на сервис позволяющий получить контент страницы врешнего виджета.
   * Сейчас это apps/proxyserver с таким url: http://localhost:3001/?url=
   */
  externalWidgetResolver?: string
  /**
   * Список url где хранятся репозитории с инфомации о доступных внешних виджетов для fdc3
   */
  externalAppDirectories?: string[]
  /**
   * Генерировать testid для UI
   */
  generateTestId?: boolean
  /**
   * Пауза после успешной операции в форме trade. Для кабинета биржи
   */
  tradeExecuteTimeout?: number
  /**
   * Записывать в openReplay сообщения из websocket
   */
  traceWs?: boolean
  /**
   * Дефолтные значения пользовательских настроек интерфейса
   */
  interfaceSettings?: {
    preferSystemCS?: boolean
    colorScheme?: string
    fontSize?: number
  }
  showLanguageSettings?: boolean
}
export let config: GlobalConfig = {
  idp: {
    authority: ''
  }
}

export function setConfig(newValue: GlobalConfig) {
  config = newValue
}

export const ConfigContext = React.createContext<GlobalConfig>(config)

export default ConfigContext
